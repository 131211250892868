import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

const AboutMe = () => {
  return (
  <Layout>
    <SEO title="Fine Art" />
    <h1>Fine Art Photography</h1>

    <h3> No Color: Group Exhibition by <a href="https://j2gallery.com">Jackson Junge Gallery</a></h3>

    <div style={{textAlign:'center'}}>
      <img style={{marginBottom:'0px', width: '600px'}} src="/Still-Open.jpg"/>
      <div><i style={{fontSize: '15px'}}>Kevin Nadro, Still Open, Photograph 24 ½" x 18 ½" (framed)</i></div>
    </div>

    <blockquote>Showcasing the talent of 33 artists, many of whom are local to the Chicagoland area, NO COLOR challenges conventional notions of artistic expression by exploring the limitless possibilities that emerge when black and white intersect. By removing color from the equation, artists are compelled to explore alternative avenues of communication and expression, leading to unexpected and compelling outcomes.</blockquote>
    
    <div><a href="https://j2gallery.com/store/special-exhibit/still-open">Still Open</a></div>
    <div>The <a href="https://j2gallery.com/exhibitions/past">exhibition</a> runs February 26th, 2024 – April 7th 3rd, 2024.</div>
    <br></br>
    <br></br>
    <br></br>

    <h3> TEN by TEN: Showcasing Chicago’s Diverse Artistry by <a href="https://j2gallery.com">Jackson Junge Gallery</a></h3>

    <div style={{textAlign:'center'}}>
      <img style={{marginBottom:'0px', width: '600px'}} src="/Nadro_Koi_Koi-INSTA.jpg"/>
      <div><i style={{fontSize: '15px'}}>Kevin Nadro, Koi Koi, Photograph, 10" x 10" Silver Halide Photograph on Aluminum</i></div>
    </div>

    <blockquote>Jackson Junge Gallery is thrilled to announce the highly anticipated return of its renowned TEN by TEN exhibition for the second consecutive year. This open-submission extravaganza proudly features over 300 talented artists from the heart of Chicago, with an impressive display of more than 400 captivating works of art. Commencing on November 3, 2023, and running until January 7, 2024, this exceptional showcase is open to the public, with no admission fee required.</blockquote>

    <div><a href="    https://j2gallery.com/store/special-exhibit/koi-koi">Koi Koi</a></div>
    <div>The <a href="https://j2gallery.com/exhibitions/past">exhibition</a> runs November 3rd, 2023 – January 7th, 2024.</div>

    <br></br>
    <br></br>
    <br></br>

    <h3>CHICAGO WORKS: A Captivating Art Exhibition by <a href="https://j2gallery.com">Jackson Junge Gallery</a></h3>
    <div style={{textAlign:'center'}}>
      <img style={{marginBottom:'0px'}} src="/bearing-the-elements.jpg"/>
      <div><i style={{fontSize: '15px'}}>Kevin Nadro, Bearing the elements, Photograph, 36 x 24 (Framed & Matted)</i></div>
    </div>

    <blockquote>Far from romanticizing the city, this exhibition seeks to unveil the raw grittiness of Chicago's lived experience while nurturing a profound admiration and appreciation for its familiar aspects. In his artwork titled "Bearing the Elements”, Kevin Nadro captures the chaos and anxiety of traversing the city amidst never-ending construction. He remarks, "As I walk around my neighborhood of Wicker Park the improvements made to the infrastructure put a strain on small sidewalks and streets. Where are you supposed to park several large trucks on a residential street? The noise, detours, and space are apparent to all". One might smile as they read this description, knowing full well the pain of trying to find parking while construction is underway. And should we even mention wintertime “dibs” law?</blockquote>


    <div style={{textAlign:'center'}}>
      <img style={{marginBottom:'0px'}} src="/strolls-and-holes.jpg"/>
      <div><i style={{fontSize: '15px'}}>Kevin Nadro, Strolls and Holes, Photograph, 37 ¼" x 25 ¼" (Framed & Matted)</i></div>
    </div>

    <div><a href="https://j2gallery.com/store/special-exhibit/strolls-and-holes">strolls and holes</a></div>
    <div><a href="https://j2gallery.com/store/special-exhibit/bearing-the-elements">bearing the elements</a></div>


    <div>The <a href="https://j2gallery.com/exhibitions/past">exhibition</a> runs July 11th, 2023 – September 3rd, 2023.</div>
    <div><a href="https://www.meer.com/en/74772-chicago-works">Press release</a></div>
  </Layout>
  )
}

export default AboutMe

